.imgContainer {
    position: relative; 
    box-sizing: border-box;
  }
  *{
    box-sizing: border-box;
  }
  
  .imageCover {
    width: 100%; 
    height: 500px;
  }
  
  .nav {
    position: absolute;
    padding: 20px;
    top: 0px; 
    left: 0px; 
    color: rgb(197, 215, 255); 
    width: 100%;
    background-color: rgba(0, 0, 0,0.53);
    z-index: 10; 
  }
  
  .social {
    display: flex;
  }
  
  .socials {
    margin-left: 10px;
  }
  
  .secondMenu{
    display: flex;
    flex-direction: column; 
    align-items: flex-end;
    border: 3px solid blue;
    color: white;
  }

  .menuContainer {
    position: absolute;
    z-index: 10; 
    top: 100px; 
    left: 50px; 
    color: white;
    display: flex;
    width: calc(100% - 100px);
  }
  
  .menuContainer a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    margin: 20px;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
  }
  
  .leftmenu, .rightmenu {
    display: flex; 
  }
  
  .rightmenu {
    margin-left: auto; 
  }
  

  .menuContainer {
    flex-direction: row;
  }
  

  .imageText span{

  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 60px;
  font-style: normal;
  /* color: rgb(255, 255, 255); */
  background-color: rgba(22, 21, 21, 0.5);
  padding: 5px;
  color: rgb(219, 140, 140);

}

.categoryCnt{
    /* background: ; */
    box-sizing: border-box;
    margin: 20px;
    width: calc(100% - 40px);
    height: 400px;
}

.categoryText span{
    margin: 20px;
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 50px;
    font-style: normal;
}

.categoryItemList{
    height: 310px;
    margin: 10px;
    display: flex;
}

.categoryItem{
    width: 25%;
    height: 280px;
    margin: 10px;
}

.categoryItem img{
    border-radius: 20px;
}

.categoryItem span{
    display: inline-block;
    /* margin: 20px; */
    margin-top: 20px;
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 25px;
    font-style: normal;
    color: rgb(0, 0, 0);
}

@media only screen and (max-width: 800px) {
    .categoryCnt{
        /* background: ; */
        box-sizing: border-box;
        margin: 20px;
        width: calc(100% - 40px);
        height: auto;
    }
    .categoryItemList{
        align-items: center;
        height: auto;
        margin: 10px;
        display: flex;
        flex-direction: column;
    }
    .categoryItem{
        width: 80%;
        height: 280px;
        margin: 10px;
    }
  }
  

.categoryItemS{
    width: 100%;
    height: 280px;
    margin: 10px;
}

.categoryItemS span{
    display: inline-block;
    /* margin: 20px; */
    margin-top: 20px;
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 25px;
    font-style: normal;
    color: rgb(0, 0, 0);
}

.categoryCntw{
    /* background: ; */
    box-sizing: border-box;
    margin: 20px;
    width: calc(100% - 40px);
    height: 500px;
}

.categoryItemw{
    width: 25%;
    height: 400px;
    margin: 10px;
}

.categoryItemw span{
    display: inline-block;
    /* margin: 20px; */
    margin-top: 20px;
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 25px;
    font-style: normal;
    color: rgb(0, 0, 0);
}

.categoryItemw img{
    border-radius: 20px;
}

.categoryItemListw{
    height: 310px;
    margin: 10px;
    display: flex;
}

@media only screen and (max-width: 800px) {
    .categoryCntw{
        /* background: ; */
        box-sizing: border-box;
        margin: 20px;
        width: calc(100% - 40px);
        height: auto;
    }
    .categoryItemListw{
        /* align-items: center; */
        height: 100%;
        margin: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
    .categoryItemw{
        width: calc(50% - 20px);
        margin: 10px;
        float: left;
    }
  }

.serviceDesc{
    display: inline-block;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}


.mapp{
    /* margin: 30px; */
    width: 100%;
    height: 100%;
}

.contactContainer{
    margin: 20px;
    margin-top: 70px;
    height: 450px;
    width: calc(100% - 40px);
    display: flex;
}

#aboutus{
    background-color: rgb(253, 237, 218);
    margin: 20px;
    margin-top: 70px;
    height: 450px;
    width: calc(100% - 40px);
    display: flex;
    border-radius: 20px;
}

.forMap,.forInfos{
    width: 50%;
    margin: 10px;
    float: left;
}

.forInfosa{
    width: 50%;
    margin: 10px;
    float: left;
}
@media only screen and (min-width: 800px) {
    .cntDescription h3{
        margin-top: 20px;
        font-family: "EB Garamond", serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-size: 25px;
        font-style: normal;
        color: rgb(0, 0, 0);
        text-align: left;
    }

    .forabout{
        padding: 30px;
        width: 50%;
        margin: 10px;
        float: left;
    }
    .usefulLinks{
        float: left;
        width: 25%;
        border: 1px solid black;
        margin: 10px;
    }
    .footerContainer{
        width: 100%;
        display: flex;
        height: 300px;
        background: black;
    }
    .imageText{
        position: absolute;
        top: 250px;
        margin-left: 120px;
    }
    .sbuttonCnt{
        display: none;
    }

    .whatsAppCnt{
        display: none;
    }
    .phone, .email {
        margin-top: 5px;
        margin-left: 30px;
      }
}

.whatsAppCnt a{
    text-decoration: none;
    color: rgb(0, 0, 0);

}

@media only screen and (max-width: 800px) {
    .sbuttonCnt{
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background-color: white;
        transition: transform 0.3s ease;
    }
    .hidden {
        transform: translateY(-100%);
    }
    .phone, .email {
        margin: auto;
        
        /* height: 100%; */
        color: black;
      }
    .imageText{
        position: absolute;
        top: 250px;
        margin-left: 20px;
    }
    .imageText span{
        font-family: "EB Garamond", serif;
        font-optical-sizing: auto;
        font-weight: 200;
        font-size: 30px;
        font-style: normal;
        color: rgb(219, 140, 140);
        text-align: left;
      }
    .usefulLinks{
        width: 100%;
        margin: 0px;
        margin-bottom: 10px;
    }
    .footerContainer {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        background: black;
    }
    #aboutus{
        background-color: rgb(253, 237, 218);
        margin: 20px;
        margin-top: 70px;
        height: 650px;
        width: calc(100% - 40px);
        display: flex;
        border-radius: 20px;
    }
    .forInfosa{
        width: 100%;
        margin: 10px;
        float: right;
        display: flex;
    }
    .forabout{
        padding: 30px;
        width: 100%;
        float: right;
    }
    #aboutus{
        display: block;
    }
    .contactContainer{
        margin: 20px;
        margin-top: 70px;
        height: auto;
        width: calc(100% - 40px);
        display: flex;
        flex-direction: column;
}
    .location{
        flex-direction: column;
        align-items: center;
    }
    .forMap{
        height: 400px;
        z-index: 1;
    }
    .forMap,.forInfos{
        width: 100%;
        margin: 10px;
    }
    .cntDescription h3 {
        margin-top: 20px;
        font-family: "EB Garamond", serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-size: 25px;
        font-style: normal;
        color: rgb(0, 0, 0);
        text-align: center;
    }
    .leftmenu,.rightmenu{
        display: none;
    }

    .nav{
        display: none;
    }

    .whatsAppCnt{
        right: 10px;
        bottom: 10px;
        position: fixed;
        width: 60px;
        height: 60px;
        background-color: rgb(35, 225, 35);
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index:20;
    }
  }


.forMap{
    padding: 30px;
}

.aboutDesc h2{
    margin: 20px;
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 50px;
    font-style: normal;
}

.aboutP{
    display: inline-block;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}



.forabout img{
    border-radius: 20px;
}

.location{
    display: flex;
    margin: 5px;
    margin-top: 15px;
}


.iconCnt,.cntDescription{
    float: left;
    margin: 10px;
}



.cntDescription h3,h5{
    margin: 3px;
}

.cntDescription h5{
    display: inline-block;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    text-align: left;
}

.iconCnt{
    /* display: flex; */
    height: 65px;
    width: 65px;
    color: rgb(190, 190, 190);
    /* margin: auto; */
}




.usefulLinks h3{
    margin-top: 20px;
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 25px;
    font-style: normal;
    color: rgb(255, 255, 255);
}

.usefulLinks .usfLinks{
    text-align: left;
    display: block;
    margin-left: 70px;
    margin-top: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
}

/* .sbarCnt{
    top: 0px;
    left: 0px;
    position: fixed;
    width: 50%;
    height: 100%;
} */

.sbarCnt {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    background-color: #000000;
    color: rgb(132, 96, 96);
    height: 100vh;
}




.ps-sidebar-container{
    background-color: black !important;
}

.sidebutton{
    position: fixed;
    top: 0;
    left: 0;

    width: 40px;
    height: 40px;
    border-radius: 0;
    border: none;
    background-color: white;
}



.forabout img{
    width: 100%;
    height: 100%;
}


.email-popup {
    position: absolute;
    z-index: 1000; /* Ensure it appears above other content */
    background-color: white; /* Background color for visibility */
    /* border: 1px solid #ccc; Border for definition */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 10px 8px rgba(255, 255, 255, 0.5); /* Shadow for depth */
    padding: 10px; /* Padding for inner content */
}


.email-popup a {
    display: block; 
    margin: 5px 0; 
    padding: 8px; 
    color: black; 
    cursor: pointer; 
    font-size: 16px;
}

.email-popup button{
    /* margin: 5px 0;  */
    font-size: 16px;
    padding: 8px; 
    color: black; 
    border: none;
    background-color: white;
}

.email-popup a {
    text-decoration: none; 
}
